/** @format */

.footer {
  padding-top: 1rem;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: rgb(70, 70, 70);
  color: rgb(201, 201, 201);
}
.wrapper {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  min-height: 100%;
  position: relative;
  width: 100%;
}
.header {
  position: fixed;
  height: 60px;
  width: 100vw;
  background-color: var(--primaryColor);
}
.bottomline {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.line {
  border-top: solid;
  width: 50vw;
  margin-bottom: 1rem;
  border-width: 1px;
  border-color: rgb(201, 201, 201);
}
.links {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  min-height: 70px;
  margin-bottom: 1.5rem;
}
.content {
  flex: 1;
  padding-top: 30px;
  min-height: calc(100vh - 30px);
}
.container {
  display: flex;
  flex-direction: row;
}
.parent {
  margin: 2rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  justify-content: center;
  font-size: medium;
}

.div1 {
  grid-area: 1 / 1 / 2 / 2;
}
.div2 {
  grid-area: 1 / 2 / 2 / 3;
}
.div3 {
  grid-area: 1 / 3 / 2 / 4;
}
.div4 {
  grid-area: 1 / 4 / 2 / 5;
}
.div5 {
  grid-area: 2 / 3 / 3 / 4;
}
.div6 {
  grid-area: 3 / 3 / 4 / 4;
}
.div7 {
  grid-area: 4 / 3 / 5 / 4;
}
.div8 {
  grid-area: 2 / 4 / 3 / 5;
}
.div9 {
  grid-area: 3 / 4 / 4 / 5;
}
.div10 {
  grid-area: 4 / 4 / 5 / 5;
}
.div11 {
  grid-area: 5 / 4 / 6 / 5;
}
.div12 {
  grid-area: 2 / 1 / 6 / 2;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.div1,
.div2,
.div3,
.div4 {
  font-weight: bold;
  color: white;
}
.parent div {
  margin-left: 2rem;
}
.deco {
  cursor: pointer;
}
.icon {
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 700px) {
  .strich {
    display: none;
  }
  .icon {
    justify-content: center;
  }
  .links {
    flex-direction: column;
  }
  .parent div {
    margin-left: 0;
  }
  .parent {
    margin: "1rem";
    text-align: center;
    align-items: center;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto repeat(12, 1fr);
  }
  .div1 {
    grid-area: 1 / 1;
  }
  .div2 {
    grid-area: 4 / 1;
  }
  .div3 {
    grid-area: 5 / 1;
  }
  .div4 {
    grid-area: 9 / 1;
  }
  .div5 {
    grid-area: 6/ 1;
  }
  .div6 {
    grid-area: 7 / 1;
  }
  .div7 {
    grid-area: 8 / 1;
  }
  .div8 {
    grid-area: 10 / 1;
  }
  .div9 {
    grid-area: 11 / 1;
  }
  .div10 {
    grid-area: 12 / 1;
  }
  .div11 {
    grid-area: 13 / 1;
  }
  .div12 {
    grid-area: 2 / 1;
  }
}
