.bannerContainer {
  border-color: lightgray;
  border: solid;
  border-radius: 20px;
  border-width: 1px;
  margin-bottom: 1rem;
  margin: 10px 0;
  padding: 10px;
}

.subHeading {
  font-size: 1rem;
  font-family: "Roboto";
  font-weight: bold;
  line-height: 24px;
  color: #303030;
}

.bannerText {
  font-size: 0.6rem;
}

.moreInformationsLabel {
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.cookieInformationTable {
  margin: 1rem;
}

.backButton {
  display: flex;
  justify-content: center;
  margin: 1rem;
}

@media screen and (max-width: 500px) {
  .heading {
    font-size: 2rem;
  }
}
