/** @format */
@tailwind base;
@tailwind components;
@tailwind utilities;
@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.cls-1 {
  fill: #005074;
}
.cls-2 {
  fill: #65c3af;
}
.cls-3 {
  fill: #005074;
}
.marker-cluster-small {
  background-color: #65c3af88 !important;
}

.marker-cluster-small div {
  background-color: #65c3af !important;
  color: #fff !important;
}
.marker-cluster-medium {
  background-color: #004f74b9 !important;
}
.leaflet-container {
  background: #ddd;
  outline: 0;
}
.marker-cluster-medium div {
  background-color: #005074 !important;
  color: #fff !important;
}
.marker-cluster-large {
  background-color: #004f74b9 !important;
}

.marker-cluster-large div {
  background-color: #005074 !important;
  color: #fff !important;
}
:root {
  --primaryColor: #005074;
  --secondaryColor: #65c3af;
  --mainWhite: #fff;
  --offWhite: #f7f7f7;
  --mainBlack: #222;
  --mainGrey: #ececec;
  --darkGrey: #dddddd;
  --mainTransition: all 0.3s linear;
  --mainSpacing: 3px;
  --lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
  --darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);
}

/* globals */
body {
  color: var(--mainBlack);
  background: var(--mainWhite);
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  line-height: 1.4;
}
h1 {
  font-size: 2.5rem;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 0.5em;
  color: #005074;
}
h2 {
  font-size: 2em;
  margin-bottom: 0.75em;
}
h3 {
  font-size: 1rem;
  line-height: 1;
  margin-bottom: 1em;
}
h4 {
  font-size: 18px;
  line-height: 1.25;
  font-weight: lighter;
  margin-bottom: 1.25em;
}
h5 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1.5em;
}
h6 {
  font-size: 1em;
  font-weight: bold;
  margin-bottom: 1em;
}
p {
  font-size: 1em;
  margin-bottom: 1em;
  line-height: 2;
}
.img-art {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.img-ort {
  border-radius: 1rem;
}
.img {
  filter: brightness(100%);
  transition: var(--mainTransition);
}
.img:hover {
  filter: brightness(90%);
}
.img.left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.img.leftWerbung {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.img.right {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.img.bottomright {
  border-top-right-radius: 0;
  border-bottom-right-radius: 1rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.img.topright {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.img.center {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.item-image {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}
.item-image-news {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}
.profil-image {
  margin: 10px;
}
.dropdown {
  border-bottom: solid;
  border-left: solid;
  border-right: solid;
  border-width: 1px;
  border-color: darkgrey;
}
.video-container {
  transition: border-color 1s ease;
  border: solid;
  position: relative;
  border-radius: 1rem;
  border-color: #65c3af00;
}
.video-container.speaking {
  border: solid;
  border-radius: 1rem;
  border-color: #65c3af;
}
.supportUser {
  cursor: pointer;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 0.5rem;
}
.supportUser:hover {
  background: #cccccc;
}
.supportUser.active {
  background: #a5a5a5;
}

.support-container {
  cursor: pointer;
  border-radius: 1rem;
  border-bottom-right-radius: 0;
  background: #005074;
  padding: 1rem;
  margin: 1rem;
  overflow: hidden;
  transition: height 0.5s ease, width 0.5s ease, color 0.5s ease,
    opacity 0.5s ease-in-out;
  width: 6rem;
  height: 3rem;
  position: fixed;
  bottom: 0;
  right: 0;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  opacity: 1;
  color: white;
}
.support-container.open {
  height: 30rem;
  width: 20rem;
  cursor: auto;
  background-color: white;
  border: solid;
  border-width: 1px;
  border-color: darkgray;
  color: black;
}
.support-container.inactive {
  opacity: 0;
  visibility: hidden;
}
.messageContainer::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 10px;
  border-radius: 13px;
  background-clip: padding-box;
  border: 2px solid transparent;
}

.messageContainer::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}
.messageContainer {
  border: solid;
  margin: 1rem;
  padding-left: 10px;
  border-width: 1px;
  border-radius: 10px;
  border-color: lightgrey;
  height: 100%;
  display: flex;
  flex-direction: column;
  scrollbar-width: 6px; /* Firefox */
  -ms-overflow-style: 6px; /* IE 10+ */
  overflow-y: auto;
  overflow-x: hidden;
  color: white;
  transition: color 0.5s ease;
}
.messageContainer:hover {
  color: rgba(0, 0, 0, 0.3);
}
.sr-only {
  display: none;
}
@media screen and (min-width: 800px) {
  .img {
    border-radius: 1rem;
  }
}
@media screen and (max-width: 800px) {
  .ant-modal,
  .ant-modal-content {
    height: 100vh;
    width: 100vw;
    margin: 0;
    top: 0;
  }
  .ant-modal-body {
    height: calc(100vh);
  }
  .ant-modal-centered::before {
    content: unset;
  }
  .img {
    background: linear-gradient(
      0deg,
      rgba(214, 214, 214, 1) 0%,
      rgba(255, 255, 255, 1) 20%,
      rgba(255, 255, 255, 1) 100%
    );
  }
  .img.left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
