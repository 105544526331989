/** @format */

.buttons {
  animation: fadein 2s;
  color: white;
}
.menu {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media screen and (max-width: 550px) {
  .menu {
    display: none;
  }
}
.link {
  color: #005074;
  text-decoration: none;
  cursor: pointer;
}
.nav {
  position: fixed;
  height: 52px;
  width: 100vw;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 10000;
  background-color: black;
  transition: 0.5s;
  padding: 1rem;
}
.transparent {
  position: fixed;
  height: 48px;
  width: 100vw;
  padding: 1rem;
  top: 0;
  display: flex;
  z-index: 10000;
  background-color: white;

  transition: 0.5s;
}
@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
